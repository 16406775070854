import $ from "jquery";
import md5 from "md5";
import _ from 'lodash';

import { client_id, client_secret } from "../../public/config.js";

let CLIENT_ID = client_id || process.env.VUE_APP_CLIENT_ID;
let CLIENT_SECRET = client_secret || process.env.VUE_APP_CLIENT_SECRET;

const localStorageArr = [
  "vuex",
  "project_Id",
  "cdms_userName",
  "cdms_userId",
  "cdms_phone"
];
export const delLocalStorage = () => {
  localStorageArr.forEach(item => {
    localStorage.removeItem(item);
  });
};

/**
 * Cookie操作
 */

export const delCookie = name => {
  let exp = new Date();
  exp.setTime(exp.getTime() - 1);
  let cval = getCookie(name);
  if (cval != null) {
    if (document.URL.indexOf(".yihuayidong.com") > -1 && document.URL.indexOf("test") <0) {
      document.cookie =
        name +
        "=" +
        cval +
        ";expires=" +
        exp.toGMTString() +
        ";domain=.yihuayidong.com";
    } else {
      document.cookie = name + "=" + cval + ";expires=" + exp.toGMTString();
    }
  }
};

export const setCookie = (name, value, time, path, domain, secure) => {
  let cookieText = encodeURIComponent(name) + "=" + encodeURIComponent(value);
  // if (time) {
  //   let exp = new Date();
  //   exp.setTime(exp.getTime() + time * 1000);
  //   cookieText += "; expires=" + exp.toGMTString();
  // }
  if (path) {
    cookieText += "; path=" + path;
  }
  if (document.URL.indexOf(".yihuayidong.com") > -1) {
    cookieText += "; domain=" + (domain || ".yihuayidong.com");
  }
  if (secure) {
    cookieText += "; secure";
  }
  document.cookie = cookieText;
};

export const _setCookie = (name, value, time, path, domain, secure) => {
  let cookieText = encodeURIComponent(name) + "=" + encodeURIComponent(value);
  if (time) {
    let exp = new Date();
    exp.setTime(exp.getTime() + time * 1000);
    cookieText += "; expires=" + exp.toGMTString();
  }
  if (path) {
    cookieText += "; path=" + path;
  }
  if (domain) {
    cookieText += "; domain=" + domain;
  }
  if (secure) {
    cookieText += "; secure";
  }
  document.cookie = cookieText;
};

export const getCookie = name => {
  let arrCookie = document.cookie.split("; ");
  for (let i = 0; i < arrCookie.length; i++) {
    let cookieItem = arrCookie[i].split("=");
    if (cookieItem[0] == name) {
      return cookieItem[1];
    }
  }
  return undefined;
};

export const getNoceStr = no_cache => {
  if (no_cache) return Math.ceil(Math.random() * 1000 + 100000);

  let run_count = localStorage.getItem("run_count") || 1;
  localStorage.setItem("run_count", parseInt(run_count) + 1);
  if (run_count == 3) localStorage.setItem("run_count", 1);

  let noce_str = "";
  if (run_count == 2 || run_count == 3) {
    noce_str = localStorage.getItem("noce_str");
  } else {
    noce_str = Math.ceil(Math.random() * 1000 + 100000);
    localStorage.setItem("noce_str", noce_str);
  }
  return noce_str;
};

export const getFiSign = (data, noce_str) => {
  if (!noce_str) noce_str = getNoceStr();
  let keyArr = [];
  data = data ? data : {};
  let dataMerge = Object.assign(data, {
    authorization: getFiAccessToken(),
    client_id: CLIENT_ID,
    app_source: "web",
    nonce: getNoceStr()
  });
  for (let k in dataMerge) {
    if (dataMerge[k] === undefined || k === "sign") {
      continue;
    }
    if (dataMerge[k] === null) {
      dataMerge[k] = "";
    }
    keyArr.push(k);
  }
  keyArr=_.sortBy(keyArr)
  // keyArr = keyArr.sort(function(a, b) {
  //   return a.localeCompare(b);
  // });
  let str = "";
  for (let i in keyArr) {
    str += keyArr[i] + urlencode(dataMerge[keyArr[i]]);
  }
  delete data.authorization;
  // localStorage.setItem('CLIENT_SECRET',JSON.stringify(keyArr) );
  return md5(CLIENT_SECRET + str + CLIENT_SECRET).toUpperCase();
};

export const urlencode = str => {
  str = (str + "").toString();
  return encodeURIComponent(str)
    .replace(/!/g, "%21")
    .replace(/'/g, "%27")
    .replace(/\(/g, "%28")
    .replace(/\)/g, "%29")
    .replace(/\*/g, "%2A")
    .replace(/%20/g, "+")
    .replace(/~/g, "%7E");
};
/**
 * 获取token
 */
export const getFiAccessToken = () => {
  if (getCookie("mhs_h5_access_token")) return getCookie("mhs_h5_access_token");
  let tempAccessToken = "";
  $.ajax({
    url: process.env.VUE_APP_BASE_URL + "/oauth/token",
    type: "POST",
    async: false,
    data: {
      grant_type: "client_credentials",
      client_id: CLIENT_ID,
      client_secret: CLIENT_SECRET
    },
    dataType: "json",
    success(data) {
      tempAccessToken = data.access_token;
    }
  });
  if (tempAccessToken) {
    _setCookie("mhs_h5_access_token", tempAccessToken, 7200);
  }
  return tempAccessToken;
};

/**
 * 存储localStorage
 */
export const setStore = (name, content) => {
  if (!name) return;
  if (typeof content !== "string") {
    content = JSON.stringify(content);
  }
  window.localStorage.setItem(name, content);
};

/**
 * 获取localStorage
 */
export const getStore = name => {
  if (!name) return;
  let value = window.localStorage.getItem(name);
  if (value !== null) {
    try {
      value = JSON.parse(value);
    } catch (e) {
      value = value;
    }
  }
  return value;
};

/**
 * 删除localStorage
 */
export const removeStore = name => {
  if (!name) return;
  window.localStorage.removeItem(name);
};

/**
 * @param {date} 标准时间格式:Fri Nov 17 2017 09:26:23 GMT+0800 (中国标准时间)
 * @param {type} 类型
 *   type == 1 ---> "yyyy-mm-dd hh:MM:ss.fff"
 *   type == 2 ---> "yyyymmddhhMMss"
 *   type == '' ---> "yyyy-mm-dd hh:MM:ss"
 *   type 为数值类型或者date,datetime,time
 */
export const formatDate = (date, type) => {
  if (type == "date" || type == "datetime") {
    let date = new Date(date);
  }
  let year = date.getFullYear(); //年
  let month =
    date.getMonth() + 1 < 10
      ? "0" + (date.getMonth() + 1)
      : date.getMonth() + 1; //月
  let day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate(); //日
  let hour = date.getHours() < 10 ? "0" + date.getHours() : date.getHours(); //时
  let minutes =
    date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes(); //分
  let seconds =
    date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds(); //秒
  let milliseconds =
    date.getMilliseconds() < 10
      ? "0" + date.getMilliseconds()
      : date.getMilliseconds(); //毫秒

  if (type == 1) {
    return (
      year +
      "-" +
      month +
      "-" +
      day +
      " " +
      hour +
      ":" +
      minutes +
      ":" +
      seconds +
      "." +
      milliseconds
    );
  } else if (type == 2) {
    return (
      year + "" + month + "" + day + "" + hour + "" + minutes + "" + seconds
    );
  } else if (type == 3 || type == "date") {
    return year + "-" + month + "-" + day;
  } else if (type == 4 || type == "datetime") {
    return (
      year +
      "-" +
      month +
      "-" +
      day +
      " " +
      hour +
      ":" +
      minutes +
      ":" +
      seconds
    );
  } else if (type == 6) {
    //时分
    return hour + ":" + minutes + ":00";
  } else if (type == 7) {
    //时
    return hour + ":00:00";
  } else if (type == 8) {
    //年月
    return year + "-" + month + "-01" + " " + "00:00:00";
  } else if (type == 9) {
    //年
    return year + "-01-01" + " " + "00:00:00";
  } else {
    if (!isNaN(type) && typeof type === "number") {
      return hour + ":" + minutes + ":" + seconds;
    } else {
      return date;
    }
  }
};

/**
 * 判断空值
 */
export const isEmpty = keys => {
  if (typeof keys === "string") {
    keys = keys.replace(/\"|&nbsp;|\\/g, "").replace(/(^\s*)|(\s*$)/g, "");
    if (keys == "" || keys == null || keys == "null" || keys === "undefined") {
      return true;
    } else {
      return false;
    }
  } else if (typeof keys === "undefined") {
    // 未定义
    return true;
  } else if (typeof keys === "number") {
    return false;
  } else if (typeof keys === "boolean") {
    return false;
  } else if (typeof keys == "object") {
    if (JSON.stringify(keys) == "{}") {
      return true;
    } else if (keys == null) {
      // null
      return true;
    } else {
      return false;
    }
  }

  if (keys instanceof Array && keys.length == 0) {
    // 数组
    return true;
  }
};

export const param2Obj = url => {
  const search = url.split("?")[1];
  if (!search) {
    return {};
  }
  return JSON.parse(
    '{"' +
      decodeURIComponent(search)
        .replace(/"/g, '\\"')
        .replace(/&/g, '","')
        .replace(/=/g, '":"') +
      '"}'
  );
};

export const getUrlParam = name => {
  var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)"); //构造一个含有目标参数的正则表达式对象
  var r = window.location.search.substr(1).match(reg); //匹配目标参数
  if (r != null) return unescape(r[2]); return null; //返回参数值
}

// 函数防抖
export function debounce(fn, wait) {
  let timeout = null;
  wait = wait || 600;
  return function () {
    let that = this;
    if(timeout !== null)   clearTimeout(timeout);
    timeout = setTimeout(() => {
      fn.apply(that);
    }, wait);
  }
}

// 手机号验证
export const isvalidPhone = (str) => {
  const reg = /^1[2|3|4|5|6|7|8|9][0-9]\d{8}$/;
  return reg.test(str);
};

export function uuid(){
  let i, random;
  let uuid = '';
  for (i = 0; i < 32; i++) {
    random = Math.random() * 16 | 0;
    if (i === 8 || i === 12 || i === 16 || i === 20) {
      uuid += '-';
    }
    uuid += (i === 12 ? 4 : (i === 16 ? (random & 3 | 8) : random)).toString(16);
  }
  return uuid;
}

/*对像转数组
 * 参数 obj type对象或数组
 *
 */
export const obj2Array = obj => {
  if (typeof obj == "string") {
    obj = JSON.parse(obj);
  }
  if (_.isPlainObject(obj)) {
    let _arr = [];
    for (let i in obj) {
      _arr.push(obj[i]);
    }
    return _arr;
  } else if (_.isArray(obj)) {
    return obj;
  } else {
    return [];
  }
};

// export const setUtf16 = _result => {
//   // 将表情转为字符
//   function utf16toEntities(str) {
//       var patt=/[\ud800-\udbff][\udc00-\udfff]/g; // 检测utf16字符正则
//       str = str.replace(patt, function(char){
//           var H, L, code;
//           if (char.length===2) {
//               H = char.charCodeAt(0); // 取出高位
//               L = char.charCodeAt(1); // 取出低位
//               code = (H - 0xD800) * 0x400 + 0x10000 + L - 0xDC00; // 转换算法
//               return " &#" + code + "; ";
//           } else {
//               return char;
//           }
//       });
//       return str;
//   }
//   // 将字符转为表情
//   function uncodeUtf16(str){
//       var reg = /\&#.*?;/g;
//       var result = str.replace(reg,function(char){
//           var H,L,code;
//           if(char.length == 9 ){
//               code = parseInt(char.match(/[0-9]+/g));
//               H = Math.floor((code-0x10000) / 0x400)+0xD800;
//               L = (code - 0x10000) % 0x400 + 0xDC00;
//               return unescape("%u"+H.toString(16)+"%u"+L.toString(16));
//           }else{
//               return char;
//           }
//       });
//       return result;
//   }
//   let grapStr = '&#128512; &#128513; &#128514; &#129315; &#128515; &#128516; &#128517; &#128518; &#128521; &#128522; &#128523; &#128526; &#128525; &#128536; &#129392; &#128535; &#128537; &#128538; &#128578; &#129303; &#129321; &#129300; &#129320; &#128528; &#128529; &#128566; &#128580; &#128527; &#128547; &#128549; &#128558; &#129296; &#128559; &#128554; &#128555; &#129393; &#128564; &#128524; &#128539; &#128540; &#128541; &#129316; &#128530; &#128531; &#128532; &#128533; &#128579; &#129297; &#128562; &#128577; &#128534; &#128542; &#128543; &#128548; &#128546; &#128557; &#128550; &#128551; &#128552; &#128553; &#129327; &#128556; &#128560; &#128561; &#129397; &#129398; &#128563; &#129322; &#128565; &#129396; &#128544; &#128545; &#129324; &#128567; &#129298; &#129301; &#129314; &#129326; &#129319; &#128519; &#129395; &#129402; &#129312; &#129313; &#129317; &#129323; &#129325; &#129488; &#129299; &#128520; &#128127; &#128121; &#128122; &#128128;';
//   let graphArr = grapStr.split(' ');
//   let master_result = utf16toEntities(_result);
//   let master_result_arr = master_result.split(' ');
//   let hasUtf16 = false;
//   // console.log('master_result', master_result)
//   // console.log('graphArr', graphArr)
//   let _str = '';
//   master_result_arr.forEach(item=>{
//     // console.log('item-->', item, graphArr.indexOf(item) !== -1)
//     if(graphArr.indexOf(item)!= -1) {
//       _str+=' <span style="font-size: 60px; line-height: 70px;">'+item+'</span> ';
//       hasUtf16 = true;
//     } else {
//       if(item.length>0) {
//         _str+=item;
//       }
//     }
//   })
//   return {
//     value: uncodeUtf16(_str),
//     hasUtf16,
//   }
// }

export const setUtf16 = _result => {
  // _result = '☁️☀️⛅️❄️';
  // _result = '😷';
  function isEmojiCharacter(substring) {
    // console.log('substring', substring.length)
      let flag = false;
      for ( var i = 0; i < substring.length; i++) {
          var hs = substring.charCodeAt(i);
          if (0xd800 <= hs && hs <= 0xdbff) {
              if (substring.length > 1) {
                  var ls = substring.charCodeAt(i + 1);
                  var uc = ((hs - 0xd800) * 0x400) + (ls - 0xdc00) + 0x10000;
                  if (0x1d000 <= uc && uc <= 0x1f77f) {
                      flag = true;
                  }
              }
          } else if (substring.length > 1) {
              var ls = substring.charCodeAt(i + 1);
              if (ls == 0x20e3) {
                  flag = true;
              }
          } else {
              if (0x2100 <= hs && hs <= 0x27ff) {
                  flag = true;
              } else if (0x2B05 <= hs && hs <= 0x2b07) {
                  flag = true;
              } else if (0x2934 <= hs && hs <= 0x2935) {
                  flag = true;
              } else if (0x3297 <= hs && hs <= 0x3299) {
                  flag = true;
              } else if (hs == 0xa9 || hs == 0xae || hs == 0x303d || hs == 0x3030
                      || hs == 0x2b55 || hs == 0x2b1c || hs == 0x2b1b
                      || hs == 0x2b50) {
                  flag = true;
              }
          }
      }
      if(
        substring.includes('☁️') || substring.includes('☀️') || substring.includes('⛅️') ||
        substring.includes('❄️')
        ) {
        flag = true;
      }
      return flag;
  }
  let val = '';
  let hasUtf16 = false;
  if(isEmojiCharacter(_result)) {
    val = ' <span style="font-size: 60px; line-height: 70px;">'+_result+'</span> ';
    hasUtf16 = true;
  } else {
    val = _result;
  }
  return {
    value: val,
    hasUtf16,
  }
}

export const random_string = len => {
  len = len || 32;
  let chars = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678";
  let maxPos = chars.length;
  let pwd = "";
  for (let i = 0; i < len; i++) {
    pwd += chars.charAt(Math.floor(Math.random() * maxPos));
  }
  return pwd;
};
export const set_file_name = filename => {
  let suffix = get_file_name(filename);
  return Date.now() + random_string(10) + suffix;
};
export const get_file_name = filename => {
  let pos = filename.lastIndexOf('.');
  let suffix = '';
  if (pos !== -1) {
    suffix = filename.substring(pos);
  }
  return suffix;
};

export const convertFile = base64 => {
  let fileArray = base64.split(','),
    // 过滤出文件类型
    fileType = fileArray[0].match(/:(.*?);/)[1],
    // atob 是对经过 base-64 编码的字符串进行解码
    bstr = atob(fileArray[1]),
    n = bstr.length,
    //Uint8Array 数组类型表示一个 8 位无符号整型数组
    u8arr = new Uint8Array(n)
  while (n--) {
    // 返回字符串n个字符的 Unicode 编码
    u8arr[n] = bstr.charCodeAt(n)
  }
  // return new Blob([u8arr], { type: fileType })
  return new File([u8arr], '文件名', { type: fileType })
}

export const dataURItoBlob = base64Data => {
  var byteString;
  if (base64Data.split(',')[0].indexOf('base64') >= 0)
    byteString = atob(base64Data.split(',')[1]);
  else byteString = unescape(base64Data.split(',')[1]);
  var mimeString = base64Data
    .split(',')[0]
    .split(':')[1]
    .split(';')[0];
  var ia = new Uint8Array(byteString.length);
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ia], { type: mimeString });
};

