import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

//解决编程式路由往同一地址跳转时会报错的情况
const originalPush = VueRouter.prototype.push;
const originalReplace = VueRouter.prototype.replace;
//push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch(err => err);
};
//replace
VueRouter.prototype.replace = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalReplace.call(this, location, onResolve, onReject);
  return originalReplace.call(this, location).catch(err => err);
};

  const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/home/index.vue'),//@/views/Home.vue
    meta: {
      active: 'home-page',
      title: '心理健康服务平台',
      // title: process.env.VUE_APP_BGIMAGE == 1 ? '心理健康服务平台' : '海棠心悦'
    }
  },
  // {
  //   path: '/test-home',
  //   name: 'test-home',
  //   component: () => import('@/views/test/test-home.vue'),
  //   meta: {
  //     title: process.env.VUE_APP_BGIMAGE == 1 ? '心理健康服务平台' : '海棠心悦'
  //   }
  // },
  {
    path: '/transfer_station',
    name: 'transfer_station',
    component: () => import('@/components/transfer_station.vue'),
    meta: { title: '中转' }
  },
  {
    path: '/doc_index',
    name: 'doc_index',
    component: () => import('@/views/doctor/doc_index.vue'),
    meta: { title: '医生主页' }
  },
  {
    path: '/doctor_help',
    name: 'doctor_help',
    component: () => import('@/views/doctor/doctor_help.vue'),
    meta: { title: '我的' }
  },
  {
    path: '/doc_consult_list',
    name: 'doc_consult_list',
    component: () => import('@/views/doctor/doc_consult_list.vue'),
    meta: { title: '我的咨询' }
  },
  {
    path: '/confirm_appointment',
    name: 'confirm_appointment',
    component: () => import('@/views/doctor/confirm_appointment.vue'),
    meta: { title: '确认预约' }
  },
  {
    path: '/change_area',
    name: 'change_area',
    component: () => import('@/views/doctor/change_area.vue'),
    meta: { title: '切换区域' }
  },
  {
    path: '/team',
    name: 'team',
    component: () => import('@/views/team/team.vue'),
    meta: { title: '团体测评' }
  },
  {
    path: '/phone',
    name: 'phone',
    component: () => import('@/views/phone/phone.vue'),
    meta: { title: '热线电话' }
  },
  {
    path: '/course',
    name: 'course',
    component: () => import('@/views/video/course.vue'),
    meta: { title: '心理课程' }
  },
  {
    path: '/courseList',
    name: 'courseList',
    component: () => import('@/views/video/courseList.vue'),
    meta: { title: '心理课程' }
  },
  {
    path: '/videoHistory',
    name: 'videoHistory',
    component: () => import('@/views/video/history.vue'),
    meta: { title: '观看历史' }
  },
  {//写的
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */  '@/components/login.vue'),
    meta: { title: '登录' }
  },
  {//写的
    path: '/login_for_doc',
    name: 'login_for_doc',
    component: () => import(/* webpackChunkName: "login" */  '@/components/login_for_doc.vue'),
    meta: { title: '登录-医生版' }
  },
  {//写的
    path: '/register',
    name: 'register',
    component: () => import('@/components/register.vue'),
    meta: { title: '账号注册' }
  },
  {//写的
    path: '/forget-password',
    name: 'forget-password',
    component: () => import('@/components/forgetpassword.vue'),
    meta: { title: '重置密码' }
  },
  {//写的
    path: '/password-reset',
    name: 'password-reset',
    component: () => import('@/views/personal/passwordreset.vue'),
    meta: { title: '重置密码' }
  },
  {//绑定
    path: '/bind-page',
    name: 'bind-page',
    component: () => import('@/views/personal/bindpage.vue'),
    meta: { title: '更换绑定手机号' }
  },
  {//写的
    path: '/home',
    name: 'home',
    component: () => import('@/views/personal/home.vue'),
    meta: { title: '我的' }
  },
  {
    path: '/record-list',
    name: 'record-list',
    component: () => import('@/views/personal/record-list.vue'),
    meta: { title: '健康档案列表' }
  },
  {
    path: '/error',
    name: 'error',
    component: () => import('@/views/personal/error'),
    meta: { title: '提示页' }//登录后完善信息
  },
  {//写的
    path: '/information',
    name: 'information',
    component: () => import('@/views/personal/information.vue'),
    meta: { title: '个人信息' }
  },
  {//写的
    path: '/my-assess',
    name: 'my-assess',
    component: () => import('@/views/personal/myassess.vue'),
    meta: { title: '我的测评' }
  },
  {//写的
    path: '/my-report',
    name: 'my-report',
    component: () => import('@/views/personal/myreport.vue'),
    meta: { title: '我的报告' }
  },
  {//写的
    path: '/report',
    name: 'report',
    component: () => import('@/views/personal/report.vue'),
    meta: { title: '测评结果' }
  },

  {//定制化个人报告
    path: '/custom-report',
    name: 'custom-report',
    component: () => import('@/views/customreport/custom-report.vue'),
    meta: { title: '测评结果' }
  },
  {//写的
    path: '/footer',
    name: 'footer',
    component: () => import('@/components/footer.vue'),
    meta: { title: '测评结果' }
  },
  {//二期
    path: '/order',
    name: 'order',
    component: () => import('@/views/personal/order.vue'),
    meta: { title: '我的订单' }
  },
  {//二期
    path: '/assess',
    name: 'assess',
    component: () => import('@/views/personal/assess.vue'),
    meta: { title: '我的测评券' }
  },
  {
    path: '/question',
    name: 'question',
    component: () => import('@/views/question/question.vue'),
    meta: { title: '量表测评' }
  },
  {
    path: '/survey',
    name: 'survey',
    component: () => import('@/views/question/survey.vue'),
    meta: { title: '问卷测评' }
  },
  {
    path: '/survey2',
    name: 'survey2',
    component: () => import('@/views/question/survey2.vue'),
    meta: { title: '问卷测评' }
  },
  {
    path: '/videoList',
    name: 'videoList',
    component: () => import('@/views/doctor/videoList.vue'),
    meta: { title: '医生列表' }
  },
  {
    path: '/doctor_detail',
    name: 'doctor_detail',
    component: () => import('@/views/doctor/doctor_detail.vue'),
    meta: { title: '医生主页' }
  },
  {
    path: '/consult_im',
    name: 'consult_im',
    component: () => import('@/views/doctor/consult_im.vue'),
    meta: { title: '图文咨询' }
  },
  {
    path: '/success_appointment',
    name: 'success_appointment',
    component: () => import('@/views/doctor/success_appointment.vue'),
    meta: { title: '图文咨询' }
  },
  {
    path: '/my_consult',
    name: 'my_consult',
    component: () => import('@/views/personal/my_consult.vue'),
    meta: { title: '我的咨询' }
  },
  {
    path: '/rongcloud_talk',
    name: 'rongcloud_talk',
    component: () => import('@/views/doctor/rongcloud_talk.vue'),
    meta: { title: '我的咨询' }
  },
  {
    path: '/chatList',
    name: 'chatList',
    component: () => import('@/views/doctor/chatList.vue'),
    meta: { title: '医生列表' }
  },
  {
    path: '/healthyList',
    name: 'healthyList',
    component: () => import('@/views/healthy/index.vue'),
    meta: { title: '健康知识列表' }
  },
  {
    path: '/healthyDetail',
    name: 'healthyDetail',
    component: () => import('@/views/healthy/detail.vue'),
    meta: { title: '健康知识详情' }
  },
  {
    path: '/liveList',
    name: 'liveList',
    component: () => import('@/views/live/index.vue'),
    meta: { title: '直播列表' }
  },
  {
    path: '/liveDetail',
    name: 'liveDetail',
    component: () => import('@/views/live/detail.vue'),
    meta: { title: '直播详情' }
  },
  {
    path: '/app/doctor',
    name: 'doctor',
    component: () => import('@/views/app/doctor.vue'),
    meta: { title: '选择医生' }
  },
  {
    path: '/appDown',
    name: 'appDown',
    component: () => import('@/views/app/appDown.vue'),
    meta: { title: '选择医生' }
  },
  {
    path: '/team',
    name: 'team',
    component: () => import('@/views/doctor/team.vue'),
    meta: { title: '团测' }
  },
  {
    path: '/questionName',
    name: 'questionName',
    component: () => import('@/views/question/questionName.vue'),
    meta: { title: '测评登记' }
  },
  {
    path: '/questionList',
    name: 'questionList',
    component: () => import('@/views/question/questionList.vue'),
    meta: { title: '测评选择' }
  },
  {
    path: '/questionPay',
    name: 'questionPay',
    component: () => import('@/views/question/questionPay.vue'),
    meta: { title: '测评支付' }
  },
  {
    path: '/questionDetails',
    name: 'questionDetails',
    component: () => import('@/views/question/questionDetails.vue'),
    meta: { title: '测评详情' }
  },
  {
    path: '/invite',
    name: 'invite',
    component: () => import('@/views/invite/invite.vue'),
    meta: { title: '测评邀请页' }
  },
  {
    path: '/invite/questionName',
    name: 'inviteQuestionName',
    component: () => import('@/views/invite/questionName.vue'),
    meta: { title: '测评邀请' }
  },
  {
    path: '/health-record-infor',
    name: 'healthrecordinfor',
    component: () => import('@/views/health/healthrecordinfor.vue'),
    meta: { title: '健康档案信息' }
  },
  {
    path: '/health-record',
    name: 'health-record',
    component: () => import('@/views/health/health_record.vue'),
    meta: { title: '健康档案信息' }
  },
  {
    path: '/visiter-health-record',
    name: 'visiter-health-record',
    component: () => import('@/views/health/visiter_heath_record.vue'),
    meta: { title: '健康档案信息' }
  },
  {
    path: '/edit-record',
    name: 'edit-record',
    component: () => import('@/views/health/edit_record.vue'),
    meta: { title: '完善档案信息' }
  },
  {
    path: '/check-record',
    name: 'check-record',
    component: () => import('@/views/health/check_record.vue'),
    meta: { title: '查看基本信息' }
  },
  {
    path: '/informed-consent',
    name: 'informedconsent',
    component: () => import('@/views/official/informedconsent.vue'),
    meta: { title: '心理咨询知情同意书' }
  },//
  {
    path: '/questionInfor',
    name: 'questionInfor',
    component: () => import('@/views/question/questionInfor.vue'),
    meta: { title: '问卷完成' }
  },
  {
    path: '/ai-guide',
    name: 'guide',
    component: () => import('@/views/Guide/index'),
    meta: { title: '在线导医' }
  },
  {
    path: '/share-health',
    name: 'sharehealth',
    component: () => import('@/views/shareHealth/sharehealth'),
    meta: { title: '创建健康档案' }
  },
  {
    path: '/infor-consummate',
    name: 'inforconsummate',
    component: () => import('@/views/inforfile/inforconsummate'),
    meta: { title: '完善注册' }//登录后完善信息
  },
  {
    path: '/install',
    name: 'install',
    component: () => import('@/views/personal/install'),
    meta: { title: '设置' }
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },{
    path: '/appdownload',
    name: 'appdownload',
    component: () => import('@/views/app/appdownload'),
    meta: { title: '海棠心悦App下载' }
  },//下载C-BCTappdownloadcbct
  {
    path: '/appdownloadcbct',
    name: 'appdownloadcbct',
    component: () => import('@/views/app/appdownloadcbct'),
    meta: { title: '下载C-BCT APP' }
  },
  {//公告栏
    path: '/bulletinboard',
    name: 'bulletinboard',
    component: () => import('@/views/personal/bulletinboard'),
    meta: { title: '公告栏' }
  },
  {//留言板
    path: '/message/list',
    name: 'message-list',
    component: () => import('@/views/message/index'),
    meta: { title: '留言板' }
  },
  {//新增留言
    path: '/message/add',
    name: 'message-add',
    component: () => import('@/views/message/add'),
    meta: { title: '添加留言板' }
  },
  {
    path: '/teamEvalAttendCenterQrcode',
    name: 'teamEvalAttendCenterQrcode',
    component: () => import('@/views/team/teamEvalAttendCenterQrcode.vue'),
    meta: { title: '团测' }
  },
  {//生成二维码
    path: '/qrcode',
    name: 'qrcode',
    component: () => import('@/views/team/qrCode'),
    meta: { title: '生成二维码' }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

export default router
