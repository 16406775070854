import {
  appConfig
} from "@/api/api";
export const GET_APP_CONFIG = "GET_APP_CONFIG";

export default {
  state: {
    appConfig: {}
  },
  mutations: {
    [GET_APP_CONFIG](state, data) {
      state.appConfig = data;
    }
  },
  actions: {
    appConfig({ commit }, data) {
      return new Promise((resolve, reject) => {
        appConfig().then(res => {
          commit(GET_APP_CONFIG, res.data);
          resolve(res.data);
        });
      });
    }
  }
};
