export default {
  state: {
    coverShow: true,
  },
  mutations: {
    COVERSHOW(state, data) {
      state.coverShow = data;
    },
  },
  actions: {
 
  }
};
