'use strict'
import axios from 'axios'
/**
 * 初始化 ws 驱动 建立连接
 */
class WxSdk {
    constructor() {
        this.apiUrl = ``
        this.title = ""
        this.desc = ""
        this.imgUrl = ''
        this.link = window.location.href
        this.debug = true
    }
    // 连接
    async init(shareModel, debug = false) {
        this._setUrl()
        this._setDebug(debug)
        this._SetShareModel(shareModel)
        const result = await this._fetch(this.apiUrl)
        if (result.status == 200 && result.data.code == 0) {
            // appId // 必填，公众号的唯一标识
            // timestamp // 必填，生成签名的时间戳
            // nonceStr // 必填，生成签名的随机串
            // signature
            const { appId, nonceStr, timestamp, signature } = result.data.data
            const configModel = { appId, nonceStr, timestamp, signature }
            this._setConfig(configModel)
        }else{
            console.log("js api err")
        }

    }
    _fetch(url) {
        return new Promise(async (resolve, reject) => {
            try {
                const result = await axios.get(url)
                resolve(result)
            } catch (error) {
                reject(error)
            }
        })
    }
    _setConfig(configModel) {
        const { appId, nonceStr, timestamp, signature } = configModel
        if (wx) {
            wx.config({
                debug: this.debug,
                appId: appId,
                timestamp: timestamp,
                nonceStr: nonceStr,
                signature: signature,
                jsApiList: [
                    // 所有要调用的 API 都要加到这个列表中
                    'checkJsApi',
                    'onMenuShareTimeline',
                    'onMenuShareAppMessage',
                    'onMenuShareQQ',
                    'onMenuShareWeibo',
                    'onMenuShareQZone'
                ],
                openTagList: ['wx-open-launch-app']
            });
            wx.ready(() => {
                //分享到朋友圈
                wx.onMenuShareTimeline({
                    title: this.title, // 分享标题
                    desc :this.desc,
                    link: this.link,
                    imgUrl: this.imgUrl, // 分享图标
                    success: () => {
                        // 分享成功执行此回调函数
                        alert('success');
                    },
                    cancel: () => {
                        alert('cancel');
                    }
                });

                //分享给朋友
                wx.onMenuShareAppMessage({
                    title: this.title, // 分享标题
                    desc :this.desc,
                    link: this.link,
                    imgUrl: this.imgUrl, // 分享图标
                    trigger: res => {
                        // 不要尝试在trigger中使用ajax异步请求修改本次分享的内容，因为客户端分享操作是一个同步操作，这时候使用ajax的回包会还没有返回
                    },
                    success: res => {
                        // 分享成功执行此回调函数
                        alert('已分享');
                    },
                    cancel: res => {
                        alert('已取消');
                    },
                    fail: res => {
                        alert(JSON.stringify(res));
                    }
                });

                wx.checkJsApi({
                    jsApiList: ['wx-open-launch-app'], // 需要检测的JS接口列表，所有JS接口列表见附录2,
                    success: function (res) {
                      console.log('可用')
                    },
                    fail: (err) => {
                      console.log(err, '不可用')
                    }
                  })
            });
            wx.error(e => {
                console.log("error:" + JSON.stringify(e))
            })
        } else {
            console.log("未引用jweixin.js")
        }
    }

    _SetShareModel(obj) {
        this.title = obj.title
        this.desc = obj.title
        this.imgUrl = obj.imgUrl
        this.link = obj.link
    }
    _setDebug(debug) {
        this.debug = debug
    }
    _setUrl() {
        //console.log('url1:',location.protocol + '//' + location.host + location.pathname)
        const url =encodeURIComponent(window.location.href.split('#')[0])
       // console.log('url:',url)
        this.apiUrl = `https://fa-service.yihuayidong.com/getSignPackage?return_url=${url}`
    }
    test() {
        this._setUrl()
       // console.log('test:', this)
    }
}
export const wxsdk = new WxSdk()
