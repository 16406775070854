import Qs from "qs";
export default {
  baseURL: process.env.VUE_APP_BASE_URL + "/api/v1/",
  method: "get",
  headers: {
    "Content-Type": "application/x-www-form-urlencoded"
  },
  transformRequest: [
    function(data) {
      // 由于使用的form-data传数据所以要格式化
      data = Qs.stringify(data);
      return data;
    }
  ]
};
