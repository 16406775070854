import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// import "babel-polyfill"; // 兼容360浏览器
import _ from 'lodash'
import Vant from 'vant';
import ViewUI from "view-design";
import 'view-design/dist/styles/iview.css';
import 'vant/lib/index.css';
import '@vant/touch-emulator';
import FastClick from 'fastclick'
import {wxsdk} from './components/wx'
import 'core-js/stable'; 
import 'regenerator-runtime/runtime';
import preventReClick from './utils/plugins'
// import ArmsRum from '@arms/rum-browser'
// ArmsRum.init({
//   pid: "cdrpjya2hg@19755626f85a5f8",
//   endpoint: "https://cdrpjya2hg-default-cn.rum.aliyuncs.com",
//   enableSPA: true,
//   staticResource: false,
// });
//融云
// import "@/yihua-theme/dist/iview.css";

Vue.prototype.wxsdk = wxsdk;
// Vue.prototype.preventReClick = preventReClick;

FastClick.attach(document.body);

Vue.use(Vant);
Vue.use(ViewUI);
ViewUI.LoadingBar.config({
  // color: '#5cb85c',
  // failedColor: '#f0ad4e',
  height: 3
});
Vue.prototype._ = _
Vue.config.productionTip = false

Vue.config.ignoredElements = [ // 忽略自定义元素标签抛出的报错
  'wx-open-launch-app', 
];
// console.log(process.env.VUE_APP_BGIMAGE,"lllll")

router.beforeEach(function (to, from, next) {
  document.title = to.meta.title || '心理健康服务平台'
  // document.title = to.meta.title || (process.env.VUE_APP_BGIMAGE == 1 ? '心理健康服务平台' : '海棠心悦')
  next()
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
